// Here you can add other styles
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@font-face {
  font-family: "boonhome-400";
  src: local("boonhome-400"), url("../assets/fonts/boonhome-400.woff") format("woff");
  font-weight: normal;
}

body {
  font-family: "boonhome-400";
}

// ボールのサイズ
$width: 40px;
$height: 40px;

// バウンドの距離
$bounce_height: 60px;

.wrap_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_loading {
  color: #98d8e5;
  font-size: 50px;
  display: inline-block;
  margin-left: 10px;
}

.bounceball_loading {
  position: relative;
  display: inline-block;
  height: 77px;
  width: $width;
  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #98d8e5;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}
.table th,
.table td {
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black;
}

/// DROP AND DRAG
.dragContainer {
  width: 90%;
  margin: 0 auto;
}

.dragHandler {
  padding: 1px 5px;
  // color: #000;
  // background: #ffffff;
  border-radius: 3px;
  cursor: grab;
  border: 1px solid;
}

.dragElement {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
}

.sortableHelper {
  z-index: 10000;
}

p {
  margin: 0;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

// label {
//   -webkit-touch-callout: none; /* iOS Safari */
//   -webkit-user-select: none; /* Safari */
//   -khtml-user-select: none; /* Konqueror HTML */
//   -moz-user-select: none; /* Old versions of Firefox */
//   -ms-user-select: none; /* Internet Explorer/Edge */
//   user-select: none; /* Non-prefixed version, currently
//                                   supported by Chrome, Edge, Opera and Firefox */
// }
